::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    padding-right: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #BABABA;
    border-radius: 5px;
}