body {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.gegga {
    width: 0;
}

.snurra {
    filter: url(#gegga);
}

.stopp1 {
    stop-color: #825FCC;
}

.stopp2 {
    stop-color: #1BA3F7;
}

.halvan {
    animation: Snurra1 10s infinite linear;
    stroke-dasharray: 180 800;
    fill: none;
    stroke: url(#gradient);
    stroke-width: 23;
    stroke-linecap: round;
}

.strecken {
    animation: Snurra1 3s infinite linear;
    stroke-dasharray: 26 54;
    fill: none;
    stroke: url(#gradient);
    stroke-width: 23;
    stroke-linecap: round;
}

.skugga {
    filter: blur(5px);
    opacity: 0.3;
    position: absolute;
    transform: translate(3px, 3px);
}

@keyframes Snurra1 {
    0% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: -403px;
    }
}
  