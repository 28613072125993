.deleteButton {

    &,
    * {
        fill: #a5b9d5;
    }

    &:hover {

        &,
        * {
            fill: #fa4b57;
        }
    }
}